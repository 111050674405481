import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Handling a multipart request";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "handling-a-multipart-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#handling-a-multipart-request",
        "aria-label": "handling a multipart request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Handling a multipart request`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#building-a-multipart-request"
        }}>{`Building a multipart request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sending-a-multipart-request"
        }}>{`Sending a multipart request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#receiving-a-multipart-request"
        }}>{`Receiving a multipart request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-typeparam-annotation"
        }}>{`Using type://@Param annotation`}</a></li>
    </ul>
    <p>{`Armeria provides `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` encoder and decoder built on top of
`}<a parentName="p" {...{
        "href": "https://www.reactive-streams.org/"
      }}>{`Reactive Streams`}</a>{`.`}</p>
    <h2 {...{
      "id": "building-a-multipart-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#building-a-multipart-request",
        "aria-label": "building a multipart request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building a multipart request`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` consists of multiple `}<a parentName="p" {...{
        "href": "typeplural://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
      }}>{`typeplural://BodyPart`}</a>{`.
Each `}<a parentName="p" {...{
        "href": "type://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
      }}>{`type://BodyPart`}</a>{` is divided into headers and a body. A `}<a parentName="p" {...{
        "href": "type://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
      }}>{`type://BodyPart`}</a>{` headers can be created simply
using `}<a parentName="p" {...{
        "href": "type://ContentDisposition:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContentDisposition.html"
      }}>{`type://ContentDisposition`}</a>{`.
`}<inlineCode parentName="p">{`String`}</inlineCode>{`, `}<a parentName="p" {...{
        "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
      }}>{`type://HttpData`}</a>{`, or `}<a parentName="p" {...{
        "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
      }}>{`type://StreamMessage`}</a>{` can be set as the body of the `}<a parentName="p" {...{
        "href": "type://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
      }}>{`type://BodyPart`}</a>{`.`}</p>
    <Tip mdxType="Tip">
      <p>{`You can also use `}<a parentName="p" {...{
          "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
        }}>{`type://HttpHeaders`}</a>{` to set complex headers for `}<a parentName="p" {...{
          "href": "type://BobyPart#headers()"
        }}>{`type://BobyPart#headers()`}</a>{`.`}</p>
    </Tip>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.nio.file.Path;
import com.linecorp.armeria.common.ContentDisposition;
import com.linecorp.armeria.common.multipart.BodyPart;

// Create a 'Content-Disposition' header with the 'name' parameter set to 'name'.
ContentDisposition nameDisposition = 
    ContentDisposition.of("form-data", "name");
// Create a BodyPart with 'Content-Disposition' header and its data.
BodyPart bodyPart1 = BodyPart.of(nameDisposition, "Meri Kim");

// Create a 'Content-Disposition' header with the name parameter set to "image", 
// and the filename parameter set to "profile.png"
ContentDisposition imageDisposition =
    ContentDisposition.of("form-data", "image", "profile.png");
Path image = Paths.get("/path/to/image");
// Create a BodyPart with 'Content-Disposition' header and its file.
BodyPart bodyPart2 = BodyPart.of(imageDisposition, StreamMessage.of(image));

// Create a new multipart with the two body parts.
Multipart multipart = Multipart.of(bodyPart1, bodyPart2);
`}</code></pre>
    <p>{`If we encode and print the `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` created above,`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`for (HttpData httpData : multipart.toStreamMessage().collect().join()) {
  System.out.print(httpData.toStringUtf8());
}
`}</code></pre>
    <p>{`we can see how the `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` is encoded as shown below:`}</p>
    <pre><code parentName="pre" {...{}}>{`--ArmeriaBoundaryEsbNVr9Z66DAIYIN
content-disposition:form-data; name="name"
content-type:text/plain

Meri Kim
--ArmeriaBoundaryEsbNVr9Z66DAIYIN
content-disposition:form-data; name="image"; filename="profile.png"
content-type:application/octet-stream

<binary-data>
--ArmeriaBoundaryEsbNVr9Z66DAIYIN--
`}</code></pre>
    <h2 {...{
      "id": "sending-a-multipart-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sending-a-multipart-request",
        "aria-label": "sending a multipart request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a multipart request`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` created in this way can be converted to an `}<a parentName="p" {...{
        "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
      }}>{`type://HttpRequest`}</a>{` through
`}<a parentName="p" {...{
        "href": "type://Multipart#toHttpRequest(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html#toHttpRequest(java.lang.String)"
      }}>{`type://Multipart#toHttpRequest(String)`}</a>{` and transmitted to a server using a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`WebClient client = WebClient.of("https://armeria.dev");
// Encode a \`Multipart\` into an \`HttpRequest\`
HttpRequest request = multipart.toHttpRequest("/upload");
client.execute(request).aggregate()...;
`}</code></pre>
    <h2 {...{
      "id": "receiving-a-multipart-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#receiving-a-multipart-request",
        "aria-label": "receiving a multipart request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Receiving a multipart request`}</h2>
    <p>{`On the server side, the multipart request sent from the client can be decoded into a `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{`
using `}<a parentName="p" {...{
        "href": "type://Multipart#from(HttpRequest):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html#from(com.linecorp.armeria.common.HttpRequest)"
      }}>{`type://Multipart#from(HttpRequest)`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Server.builder()
      .service((ctx, req) -> {
        // Decode an \`HttpRequest\` into a \`Multipart\`
        Multipart multipart = Multipart.from(req);
        ...
      })
`}</code></pre>
    <p>{`Since `}<a parentName="p" {...{
        "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
      }}>{`type://Multipart`}</a>{` does not have the actual multipart data, you can use
`}<inlineCode parentName="p">{`Multipart.bodyParts().subscribe(...)`}</inlineCode>{` to read data little by little as needed.
If the size of the data is not large, the data can be read after being loaded into memory through
`}<a parentName="p" {...{
        "href": "type://Multipart#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html#aggregate()"
      }}>{`type://Multipart#aggregate()`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Use a \`Subscriber\` to read the data with backpressure.
multipart.bodyParts().subsribe(new Subscriber() {
   ...
});

// Read the data after aggregation.
Multipart.from(req).aggregate()
         .thenAccept(multipart -> {
             for (AggregatedBodyPart bodyPart : multipart.bodyParts()) {
                 String content = bodyPart.contentUtf8();
                 ...
             }
         });
`}</code></pre>
    <h2 {...{
      "id": "using-typeparam-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typeparam-annotation",
        "aria-label": "using typeparam annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` annotation`}</h2>
    <p>{`In annotated services, a body part content of `}<inlineCode parentName="p">{`multipart/form-data`}</inlineCode>{` can be directly mapped into a `}<inlineCode parentName="p">{`String`}</inlineCode>{`,
`}<inlineCode parentName="p">{`Path`}</inlineCode>{`, `}<inlineCode parentName="p">{`File`}</inlineCode>{`, or `}<a parentName="p" {...{
        "href": "type://MultipartFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/MultipartFile.html"
      }}>{`type://MultipartFile`}</a>{` using the `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` annotation.`}</p>
    <Tip mdxType="Tip">
      <p>{`Note that a `}<a parentName="p" {...{
          "href": "type://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
        }}>{`type://BodyPart`}</a>{` can be converted into a `}<inlineCode parentName="p">{`Path`}</inlineCode>{`, `}<inlineCode parentName="p">{`File`}</inlineCode>{` or `}<a parentName="p" {...{
          "href": "type://MultipartFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/MultipartFile.html"
        }}>{`type://MultipartFile`}</a>{` only when
the `}<a parentName="p" {...{
          "href": "type://BodyPart#filename():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html#filename()"
        }}>{`type://BodyPart#filename()`}</a>{` and `}<a parentName="p" {...{
          "href": "type://BodyPart#name():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html#name()"
        }}>{`type://BodyPart#name()`}</a>{` is specified.`}</p>
    </Tip>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.io.File;
import java.nio.file.Path;

import com.linecorp.armeria.common.MediaTypeNames;
import com.linecorp.armeria.common.multipart.MultipartFile;
import com.linecorp.armeria.server.annotation.Consumes;
import com.linecorp.armeria.server.annotation.Post;

@Consumes(MediaTypeNames.MULTIPART_FORM_DATA)
@Post("/upload")
public HttpResponse upload(
    @Param String param,
    @Param File file,
    @Param Path path,
    @Param MultipartFile multipartFile) {
    // Do something with the multipart data
    ...
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p><a parentName="p" {...{
          "href": "type://ServerBuilder#multipartUploadsLocation(Path):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#multipartUploadsLocation(java.nio.file.Path)"
        }}>{`type://ServerBuilder#multipartUploadsLocation(Path)`}</a>{` and the files are removed as soon as the request is
handled completely by default, i.e. when the `}<a parentName="p" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` is complete. If you want to persist the
uploaded file, you can move the uploaded file to another folder or persistence layer before the deletion.`}</p>
      <p>{`Alternatively, you can disable the automatic deletion of the uploaded files by setting
`}<a parentName="p" {...{
          "href": "type://MultipartRemovalStrategy#NEVER:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/MultipartRemovalStrategy.html#NEVER"
        }}>{`type://MultipartRemovalStrategy#NEVER`}</a>{` to
`}<a parentName="p" {...{
          "href": "type://ServerBuilder#multipartRemovalStrategy(MultipartRemovalStrategy):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#multipartRemovalStrategy(com.linecorp.armeria.server.MultipartRemovalStrategy)"
        }}>{`type://ServerBuilder#multipartRemovalStrategy(MultipartRemovalStrategy)`}</a>{`, but please note that you must make
sure the uploaded files are deleted once they are not in use to avoid the excessive consumption of disk space.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      